import React, { useState } from 'react';
import Form from "react-bootstrap/Form";
import 'bootstrap/dist/css/bootstrap.css';
import Accordion from 'react-bootstrap/Accordion';
import './App.css';

const host = 'https://api.llsif.kr';
// const host = 'http://192.168.55.232';

function App() {

  const [id, setId] = useState('');
  const [pw, setPw] = useState('');
  const [is_Global, setGlobal] = useState(false);
  const [is_loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);

  // 대기 번호
  const [waitNum, setWaitNum] = useState(0);

  // 유저 정보
  const [name, setName] = useState('');
  const [rank, setRank] = useState(0);
  const [loginDays, setLoginDays] = useState(0);
  const [totalDays, setTotalDays] = useState(0);
  const [fes, setFes] = useState('');
  const [party, setParty] = useState('');
  const [normal, setNormal] = useState('');
  const [createdAt, setCreatedAt] = useState('');

  const handleChangeId = (e: React.ChangeEvent<HTMLInputElement>) => {
    //e.preventDefault(); // prevent the default action
    setId(e.target.value);
  };

  const handleChangePw = (e: React.ChangeEvent<HTMLInputElement>) => {
    //e.preventDefault(); // prevent the default action
    setPw(e.target.value);
  };

  const handleChangeGlobal = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "jp") {
      setGlobal(false);
    } else if (e.target.value === "gl") {
      setGlobal(true);
    }
  };

  const dataTransfer = async () => {
    setLoading(true);
    fetch(host + '/transfer/transfer', {
      method: 'post',
      body: JSON.stringify({
        user_id: id,
        user_pw: pw,
        is_global: is_Global
      })
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if (data.data === 'success') {
        setStatus(1);
      } else if (data.data === 'fail') {
        alert('아이디 또는 비밀번호를 잘못 입력하였습니다.\r\n다시 확인해주세요.');
      } else if (data.data === 'already') {
        alert('이미 연동 신청된 계정입니다. 신청 현황을 확인해 주세요.');
      } else if (data.data === 'time_error') {
        alert('10초에 한 번만 시도할 수 있습니다.');
      }
    }).catch(err => {
      alert('서버에 연결할 수 없습니다.');
    }).finally(() => setLoading(false));
  }

  const transferStatus = async () => {
    setLoading(true);
    fetch(host + '/transfer/status', {
      method: 'post',
      body: JSON.stringify({
        user_id: id,
        user_pw: pw,
        is_global: is_Global
      })
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if (data.data === 'complete') {
        setName(data.user.name);
        setRank(data.user.rank);
        setLoginDays(data.user.login_days);
        setTotalDays(data.user.total_days);
        setFes(data.card.fes);
        setParty(data.card.party);
        setNormal(data.card.normal);
        setCreatedAt(data.user.created_at);
        setStatus(2);
      } else if (data.data === 'waiting') {
        setWaitNum(data.wait_num)
        setStatus(3);
      } else if (data.data === 'taking_over') {
        setStatus(4);
      } else if (data.data === 'undefined') {
        alert('존재하지 않는 인계 코드입니다.');
      } else if (data.data === 'time_error') {
        alert('10초에 한 번만 시도할 수 있습니다.');
      }
    }).catch(err => {
      alert('서버에 연결할 수 없습니다.');
    }).finally(() => setLoading(false));
  }

  return (
    <>
      {is_loading &&
        <section id="loading-screen">
          <div id="loader"></div>
        </section>
      }
      <div className="Auth-form-container">
        {(status === 0) ?
          <div className="Auth-form">
            <div className="Auth-form-content">
              <h3 className="Auth-form-title">데이터 인계</h3>
              <div className="form-group mt-3">
                <label>복구 ID</label>
                <input
                  type="email"
                  className="form-control mt-1"
                  placeholder="복구 ID"
                  onChange={handleChangeId}
                />
              </div>
              <div className="form-group mt-3">
                <label>비밀번호</label>
                <input
                  type="password"
                  className="form-control mt-1"
                  placeholder="비밀번호"
                  onChange={handleChangePw}
                />
              </div>
              <div className="checkBox gap-4 mt-2">
                <Form.Check
                  type="radio"
                  value="jp"
                  name="server"
                  label="일본 서버"
                  defaultChecked={!is_Global}
                  onChange={handleChangeGlobal}
                />
                <Form.Check
                  type="radio"
                  value="gl"
                  name="server"
                  label="글로벌 서버"
                  defaultChecked={is_Global}
                  onChange={handleChangeGlobal}
                />
              </div>
              <div className='buttons'>
                <div className="d-grid gap-2 mt-3">
                  <button className="btn btn-primary" onClick={dataTransfer}>
                    신청
                  </button>
                </div>
                <div className="d-grid gap-2 mt-3">
                  <button className="btn btn-secondary" onClick={transferStatus}>
                    신청 현황
                  </button>
                </div>
              </div>
            </div>
          </div>
          : (status === 1) ?
            <div className="Status-form">
              <div className='Status-form-content'>
                <img src={process.env.PUBLIC_URL + '/thanks.jpg'} alt="thanks"></img>
                <div className='text'>
                  <h3>연동 신청이 완료되었습니다.</h3>
                  <h5>연동 중에 스쿠스타에 로그인할 시 문제가 발생할 수 있습니다.</h5>
                  <h5>인계 코드는 추후에 사용되니 따로 보관해 두시기 바랍니다.</h5>
                </div>
                <div className='toHome'>
                  <a href='/#' onClick={() => { setStatus(0) }}>홈으로 이동</a>
                </div>
              </div>
            </div>
            : (status === 2) ?
              <div className="Status-form">
                <div className='Status-form-content'>
                  <img src={process.env.PUBLIC_URL + '/complete.jpg'} alt="status"></img>
                  <div className='status'>
                    <h3>연동 완료되었습니다.</h3>
                    <h5>닉네임 : {name} / 유저 랭크 : {rank}</h5>
                    <h5>계정 생성일 : {createdAt}</h5>
                    <h5>통산 로그인 : {totalDays}일 중 <b>{loginDays}일({(loginDays / totalDays * 100).toFixed(2)}%)</b></h5>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>한정 UR 체크</Accordion.Header>
                        <Accordion.Body>
                          <a href='/#' onClick={() => { window.open('https://niihiihii.github.io/sifas_fesparty_statusboard/') }}>한정 UR 체크 사이트 열기</a>
                          <div className='data'>
                            <h6>fes_data : {fes}</h6>
                            <h6>party_data : {party}</h6>
                            <h6>normal_data : {normal}</h6>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  <div className='toHome'>
                    <a href='/#' onClick={() => { setStatus(0) }}>홈으로 이동</a>
                  </div>
                </div>
              </div>
              : (status === 3) ?
                <div className="Status-form">
                  <div className='Status-form-content'>
                    <img src={process.env.PUBLIC_URL + '/waiting.jpg'} alt="status"></img>
                    <div className='status'>
                      <h3>연동 대기중입니다.</h3>
                      <h5>대기 번호 : {waitNum}</h5>
                    </div>
                    <div className='toHome'>
                      <a href='/#' onClick={() => { setStatus(0) }}>홈으로 이동</a>
                    </div>
                  </div>
                </div>
                :
                <div className="Status-form">
                  <div className='Status-form-content'>
                    <img src={process.env.PUBLIC_URL + '/taking_over.jpg'} alt="status"></img>
                    <div className='status'>
                      <h3>현재 연동 중입니다.</h3>
                      <h5>스쿠스타 접속을 자제해 주시기 바랍니다.</h5>
                    </div>
                    <div className='toHome'>
                      <a href='/#' onClick={() => { setStatus(0) }}>홈으로 이동</a>
                    </div>
                  </div>
                </div>
        }
      </div>
    </>
  )
}

export default App;
